<template>
  <form
    @submit.prevent="handleStripePayment"
    ref="form"
    class="my-md">
    <header>
      <Heading
        :tag="'h2'"
        :level="'h3'"
        isCapitalized
        class="mb-lg">
        {{ $t('common.payment') }}
      </Heading>
    </header>

    <fieldset :disabled="isLoading">
      <label
        for="stripe-checkout"
        ref="stripe">
        <div
          id="stripe-checkout"
          ref="stripe-checkout">
        </div>
        <div
          id="card-errors"
          ref="stripe-checkout-errors"
          role="alert"
          class="StripeElementsErrors">
        </div>
      </label>
    </fieldset>

    <Button
      :isFluid="!$mediaQueries.isDesktop"
      :isLoading="isLoading"
      :disabled="isLoading"
      type="submit"
      class="my-lg">
      {{ $t('artist.dashboard.client.booking.details.validatePayment') }}
    </Button>

    <Paragraph
      v-html="$t('artist.dashboard.client.booking.cancellation.terms')"
      :size="'sm'">
    </Paragraph>
  </form>
</template>

<script>

import {
  mapState,
  mapMutations,
}                     from 'vuex';

import Button         from '../../../components/atoms/Button/a-Button.vue';
import Paragraph      from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Heading        from '../../../components/atoms/Heading/a-Heading.vue';


export default {
  name: 'o-ClientBookingPayment',
  components: {
    Button,
    Paragraph,
    Heading,
  },
  props: {
    clientSecret: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    isLoading: false,
    stripeSDK: null,
    stripeCard: null,
  }),
  computed: {
    ...mapState('User', ['locale']),
    stripeStyle() {
      return {
        base: {
          fontFamily: `'Geomanist', 'Be Vietnam', -apple-system, '.SFNSText-Regular', 'San Francisco', BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'`,
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          lineHeight: '24px',
          iconColor: '#3d0099',
          color: '#000F',
          fontWeight: 500,
          '::placeholder': {
            color: '#AAAAAA',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
          }
        },
        invalid: {
          color: '#FF3600',
        },
        complete: {
          color: '#00CE8F',
        }
      };
    },
    cardCheckoutElement() {
      return this.$refs['stripe-checkout'];
    },
    cardCheckoutErrorsElements() {
      return this.$refs['stripe-checkout-errors'];
    }
  },
  async mounted() {
    await this.initStripe();
  },
  methods: {
    ...mapMutations({ addErrors: 'ADD_ERRORS' }),
    async initStripe() {
      this.isLoading = true;

      try {
        this.stripeSDK = await window.Stripe(process.env.VUE_APP_STRIPE_API_KEY, { locale: this.locale?.locale ?? 'auto' });

        if (!this.stripeSDK) return this.addErrors([{ type: 'alert', message: this.$t('common.errors.UnknownError') }]);

        // // TODO : Test font is loaded on beta/prod env
        // const elements = this.stripeSDK.elements({ fonts: [
        //     {
        //       family: 'Geomanist',
        //       src: `url(${process.env.BASE_URL}assets/fonts/geomanist-medium-webfont.woff2)`,
        //       weight: '500',
        //     },
        //   ],
        // });

        this.stripeCard = this.stripeSDK.elements()?.create?.('card', { style: this.stripeStyle, hidePostalCode: true, });
        this.stripeCard?.on?.('change', event => this.cardCheckoutErrorsElements.textContent = event.error ? event.error.message : '');
        this.stripeCard?.mount?.(this.cardCheckoutElement);
      } catch (error) {
        this.addErrors([{ type: 'alert', message: this.$t('common.errors.UnknownError') }]);
      } finally {
        this.isLoading = false;
      }
    },
    async handleStripePayment() {
      const paymentOptions = { payment_method: { card: this.stripeCard } };

      try {
        this.isLoading = true;

        const payment = await this.stripeSDK.confirmCardPayment(this.clientSecret, paymentOptions);

        payment.error
          ? this.cardCheckoutErrorsElements.textContent = payment.error.message
          : this.$emit('success');
      } catch (error) {
        this.addErrors([{ type: 'alert', message: this.$t('common.errors.UnknownError') }]);
      } finally {
        this.isLoading = false;
      }
    },
  },
}


</script>

<style lang="scss">
</style>
