<template>
  <section :class="[
    'client-booking__wrapper',
    { 'client-booking__wrapper--custom-quote': isCustomQuoteNeeded }
  ]">
    <header class="client-booking__header">
      <Icon
        @click="handleBack"
        :variant="'arrow-left-outline'"
        :size="'xs'"
        :tag="'button'"
        :type="'button'"
        :backdropColor="'white'"
        class="mr-lg">
      </Icon>
      <Heading
        :tag="'h3'"
        :level="'h3'">
        {{
          isShowInfos
            ? $t('artist.dashboard.client.booking.details.paymentSummary')
            : $t('artist.dashboard.client.booking.details.eventSummary')
        }}
      </Heading>
    </header>

    <div
      v-if="isCustomQuoteNeeded"
      class="client-booking__custom-quote-hint-wrapper">
      <Icon
        :variant="'warning-solid'"
        :color="'alert'"
        :size="'xs'">
      </Icon>
      <Paragraph
        :color="'dark'"
        :size="'sm'">
        {{ $t('artist.dashboard.client.booking.details.customQuoteMessage') }}
      </Paragraph>
    </div>

    <Loader
      v-if="isLoading"
      :height="400">
    </Loader>
    <template v-else>
      <div class="client-booking__date">
        <div>
          <Heading
            :tag="'h5'"
            :level="'h5'"
            class="mb-xxs">
            {{ $t('artist.dashboard.landing.searchHeader.eventDate') }}*
          </Heading>
          <Paragraph>
            {{ bookingDate || $t('artist.dashboard.client.booking.details.chooseDate') }}
          </Paragraph>
        </div>
        <router-link
          :to="{
            name: 'ArtistProfilePublic',
            params: { id: currentArtist.ID },
            hash: '#artist-agenda'
          }"
          class="mb-xxs">
          <TextLink :tag="'span'">
            {{ $t('common.edit') }}
          </TextLink>
        </router-link>
      </div>

      <div
        v-if="!isCustomQuoteNeeded"
        class="client-booking__sticky">
        <BookingDetailsCard
          :artist="currentArtist"
          :booking="pendingBooking"
          class="client-booking__details-card">
        </BookingDetailsCard>
      </div>

      <ClientBookingDetailsForm
        @submit="handleBookingDetailsSubmit"
        :isCustomQuote="isCustomQuoteNeeded"
        :isShowInfos="isShowInfos"
        ref="details">
      </ClientBookingDetailsForm>
      <ClientBookingPayment
        v-if="isShowInfos"
        @success="handlePaymentSuccess"
        :clientSecret="clientSecret">
      </ClientBookingPayment>
    </template>
  </section>
</template>

<script>

import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
}                                   from 'vuex';

import Icon                         from '../../../components/atoms/Icon/a-Icon.vue';
import Loader                       from '../../../components/atoms/Loader/a-Loader.vue';
import Heading                      from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph                    from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import TextLink                     from '../../../components/atoms/TextLink/a-TextLink.vue';
import BookingDetailsCard           from '../../../components/molecules/BookingDetailsCard/m-BookingDetailsCard.vue';
import ClientBookingDetailsForm     from './o-ClientBookingDetailsForm.vue';
import ClientBookingPayment         from './o-ClientBookingPayment.vue';
import AVAILABILITIES_OPTIONS       from '../../../constants/availabilities-options.js';


export default {
  name: 'o-ClientBooking',
  inheritAttrs: false,
  components: {
    Icon,
    Loader,
    Heading,
    Paragraph,
    TextLink,
    BookingDetailsCard,
    ClientBookingDetailsForm,
    ClientBookingPayment,
  },
  data: () => ({
    isShowInfos: false,
    bookingID: '',
    clientSecret: '',
  }),
  computed: {
    ...mapState('SearchArtist', ['currentArtist', 'selectedAvailability', 'filters', 'isLoading', 'isCustomQuote']),
    ...mapGetters({
      bookingDate: 'SearchArtist/BOOKING_DATE',
      selectedAvailability: 'SearchArtist/CURRENT_ARTIST_SELECTED_AVAILABILITY',
    }),
    isCustomQuoteNeeded() {
      return Boolean(this.isCustomQuote || this.$route.query.isCustomQuote);
    },
    pendingBooking() {
      const {
        Price: { Exception: ExceptionArtist, Normal: NormalArtist },
        ClientPrice: { Exception: ExceptionClient, Normal: NormalClient },
      } = this.currentArtist;

      return {
        PriceForClient: (this.selectedAvailability?.EventType === AVAILABILITIES_OPTIONS.EXCEPTION ? ExceptionClient : NormalClient) * 100,
        PriceForArtist: (this.selectedAvailability?.EventType === AVAILABILITIES_OPTIONS.EXCEPTION ? ExceptionArtist : NormalArtist) * 100,
      };
    },
  },
  methods: {
    ...mapMutations({
      addErrors: 'ADD_ERRORS',
      setBookingDetails: 'SearchArtist/SET_BOOKING_DETAILS',
      clearBooking: 'SearchArtist/CLEAR_BOOKING',
      toggleMobileFiltersType: 'Layout/TOGGLE_MOBILE_FILTERS_TYPE',
    }),
    ...mapActions({
      bookArtist: 'SearchArtist/BOOK_ARTIST',
      getBookings: 'User/GET_BOOKINGS',
    }),
    async handleBookingDetailsSubmit(bookingDetails) {
      // eslint-disable-next-line
      const { name, customQuoteDescription, ...bookingInfos } = bookingDetails;

      this.setBookingDetails(bookingInfos);

      if (!this.$mediaQueries.isDesktop) this.$refs?.details?.$el?.scrollIntoView();
      if (this.isCustomQuoteNeeded) return this.handleCustomQuoteBooking(bookingDetails);

      const booking = {
        ...bookingDetails,
        availabilityID: this.selectedAvailability.Id,
        artistID: this.currentArtist.ID,
      };
      const stripeIntent = await this.bookArtist(booking);

      if (stripeIntent?.Secret) {
        this.isShowInfos = true;
        this.clientSecret = stripeIntent.Secret;
        this.bookingID = stripeIntent.ID;
      }
    },
    handleCustomQuoteBooking(bookingDetails) {
      const { Line1, Line2, PostalCode, City, Country, EventType, customQuoteDescription, name } = bookingDetails;
      const address = encodeURIComponent('contact@bookunartiste.com');
      const emailSubject = encodeURIComponent('Demande de devis personnalisé');
      const emailBody = encodeURIComponent(`Adresse: ${Line1}${Line2} ${PostalCode} ${City} ${Country}.\nType d'évènement: ${EventType}.\nNom du booker: ${name}.\n\nDescription de la demande: ${customQuoteDescription}`);
      const email = `mailto:${address}?subject=${emailSubject}&body=${emailBody}`;

      location.href = email;
    },
    async handlePaymentSuccess() {
      await this.getBookings();

      this.$router.push({
        name: 'ClientBookingDetails',
        params: { id: this.bookingID, },
        query: { isSuccess: true },
      });
    },
    handleBack() {
      if (this.isShowInfos) return this.isShowInfos = false;

      this.$router.push(this.currentArtist.ID
        ? { name: 'ArtistProfilePublic', params: { id: this.currentArtist.ID } }
        : { name: 'ClientDashboard' }
      );
    },
  },
}

</script>

<style lang="scss">

.client-booking {
  &__wrapper {
    @media screen and ($desktop) {
      display: grid;
      grid-template-columns: 1fr minmax(350px, auto);
      grid-column-gap: var(--space-lg);
    }

    &--custom-quote {
      grid-template-columns: 1fr;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: calc(1.5 * var(--space-lg));

    @media screen and ($desktop) {
      grid-column: 1 / -1;
    }
  }

  &__date {
    display: flex;
    align-items: flex-end;
    column-gap: var(--space-lg);
    margin-bottom: var(--space-lg);
  }

  &__custom-quote-hint-wrapper {
    display: flex;
    align-items: center;
    column-gap: var(--space-sm);
    margin-bottom: var(--space-md);
    padding: var(--space-sm);
    background-color: var(--color-grey-empty);
    border-radius: var(--rounded-sm);
  }

  &__sticky {
    @media screen and ($desktop) {
      grid-column: 2 / 3;
      grid-row: span 3;
    }
  }

  &__details-card {
    margin-top: var(--space-base);
    margin-bottom: var(--space-lg);
    padding: var(--space-xs) 0;

    @media screen and ($desktop) {
      position: sticky;
      margin: 0;
      padding: 0;
      top: calc(var(--space-lg) + var(--space-header-height));
    }
  }
}

</style>
