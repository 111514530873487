<template>
  <dl
    v-if="isShowInfos"
    class="client-booking-details-form__info-list">
    <template v-for="(info, index) in bookingInfos">
      <div
        v-if="info.value"
        :key="`info-booking-${index}`"
        class="client-booking-details-form__info-item">
        <Paragraph
          :tag="'dd'"
          :key="`booking-info-label-${index}}`">
          {{ info.label }}
        </Paragraph>
        <Paragraph
          :tag="'dt'"
          :size="'md'"
          :key="`booking-info-value-${index}}`"
          isBold>
          {{ info.value }}
        </Paragraph>
      </div>
    </template>
  </dl>

  <form
    v-else
    @submit.prevent="handlePerformanceDetailsSubmit"
    ref="form"
    novalidate
    class="client-booking-details-form__wrapper">
    <GoogleLocationFinder
      @place-selected="handleSetPerformanceLocation"
      :prefilledText="line1 || bookingDetails.Line1 || ''"
      :label="`${$t('artist.dashboard.client.booking.details.eventAddress')}*`"
      :placeholder="`${$t('artist.dashboard.client.booking.details.eventAddress')}*`"
      :options="{ types: ['(regions)'] }"
      isAutofocus
      ref="location-input"
      id="adress-main"
      isRequired>
    </GoogleLocationFinder>
    <FieldInput
      v-model="line2"
      :label="$t('common.addressComplement')"
      :placeholder="$t('common.addressComplement')"
      type="text"
      id="adress-additional">
    </FieldInput>
    <FieldInput
      v-model="city"
      :label="`${$t('common.city')}*`"
      :placeholder="`${$t('common.city')}*`"
      type="text"
      id="city"
      required>
    </FieldInput>
    <FieldInput
      v-model="postalCode"
      :label="`${$t('common.zipCode')}*`"
      :placeholder="`${$t('common.zipCode')}*`"
      type="text"
      required
      id="postal-code">
    </FieldInput>
    <FieldInput
      v-model="country"
      :label="`${$t('common.country')}*`"
      :placeholder="`${$t('common.country')}*`"
      type="text"
      id="country"
      disabled
      required>
    </FieldInput>
    <FieldInput
      v-if="!isCustomQuote"
      v-model="numberOfPeople"
      :label="`${$t('artist.dashboard.client.booking.details.numberOfPeople')}*`"
      :placeholder="`${$t('artist.dashboard.client.booking.details.numberOfPeople')}*`"
      :minNumberValue="1"
      type="number"
      id="counter"
      required>
    </FieldInput>
    <FieldInput
      v-model="eventType"
      :label="`${$t('artist.dashboard.client.booking.details.eventType')}*`"
      :placeholder="`${$t('artist.dashboard.client.booking.details.eventType')}*`"
      type="text"
      id="type"
      required>
    </FieldInput>
    <template v-if="isCustomQuote">
      <FieldInput
        v-model="name"
        :label="`${$t('common.fullName')}*`"
        :placeholder="`${$t('common.fullName')}*`"
        type="text"
        id="name"
        required>
      </FieldInput>
      <FieldInput
        v-model="customQuoteDescription"
        :label="`${$t('artist.dashboard.client.booking.details.customQuoteAsk')}*`"
        :placeholder="`${$t('artist.dashboard.client.booking.details.customQuoteAsk')}*`"
        :charactersLimit="1000"
        :key="isCustomQuote"
        type="textarea"
        id="custom-description"
        class="client-booking-details-form__quote-details"
        required>
      </FieldInput>
    </template>
    <template v-else>
      <FieldInput
        v-model="startTime"
        :label="`${$t('common.startTime')}*`"
        :placeholder="$t('common.startTime')"
        type="time"
        id="start-time"
        required>
      </FieldInput>
      <FieldInput
        :value="endTime"
        :label="$t('common.endTime')"
        :placeholder="$t('common.endTime')"
        type="time"
        id="end-time"
        :disabled="true"
        required>
      </FieldInput>
    </template>
    <Paragraph
      :size="'sm'"
      class="mt-lg">
      *{{ $t('common.mandatoryField') }}
    </Paragraph>
    <Button
      type="submit"
      class="client-booking-details-form__cta">
      {{ $t('common.continue') }}
    </Button>
  </form>
</template>

<script>

import {
  mapState,
  mapGetters,
  mapMutations,
}                                               from 'vuex';

import Paragraph                                from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import FieldInput                               from '../../../components/atoms/FieldInput/a-FieldInput.vue';
import Button                                   from '../../../components/atoms/Button/a-Button.vue';
import GoogleLocationFinder                     from '../../../components/molecules/GoogleLocationFinder/m-GoogleLocationFinder.vue';
import countriesList                            from '../../../constants/countriesList.js';
import {
  getDateAsDDMMYYYY,
  addHoursToTime,
}                                               from '../../../utils/dateUtils.js';
import {
  getKilometersDistanceBetweenCoordinates,
}                                               from '../../../utils/geolocationUtils.js';


export default {
  name: 'o-ClientBookingDetailsForm',
  components: {
    Paragraph,
    FieldInput,
    Button,
    GoogleLocationFinder,
  },
  props: {
    isCustomQuote: {
      type: Boolean,
      default: false,
    },
    isShowInfos: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    countriesList,
    availabilityID: '',
    line1: '',
    line2: '',
    postalCode: '',
    city: '',
    country: '',
    numberOfPeople: '',
    eventType: '',
    name: '',
    customQuoteDescription: '',
    isMaterialNeeded: false,
    startTime: null,
    performanceLocation: null,
  }),
  computed: {
    ...mapState('SearchArtist', ['filters', 'currentArtist', 'selectedAvailability', 'currentArtistLocation', 'bookingDetails']),
    ...mapGetters({
      bookingDate: 'SearchArtist/BOOKING_DATE',
    }),
    endTime() {
      if (!this.startTime || !this.selectedAvailability.timestamp) return null;

      const date = new Date(this.selectedAvailability.timestamp);
      const hoursToAdd = this.currentArtist?.PerformanceDuration ?? 0;

      return addHoursToTime({ date, startTime: this.startTime, hours: hoursToAdd });
    },
    bookingInfos() {
      return [
        {
          label: this.$t('common.address'),
          value: this.line1,
        },
        {
          label: this.$t('common.addressComplement'),
          value: this.line2,
        },
        {
          label: this.$t('common.city'),
          value: this.city,
        },
        {
          label: this.$t('common.zipCode'),
          value: this.postalCode,
        },
        {
          label: this.$t('common.country'),
          value: this.country,
        },
        {
          label: this.$t('common.startTime'),
          value: this.startTime,
        },
        {
          label: this.$t('common.endTime'),
          value: this.endTime,
        },
        {
          label: this.$t('artist.dashboard.client.booking.details.eventType'),
          value: this.eventType,
        },
        {
          label: this.$t('artist.dashboard.client.booking.details.numberOfPeople'),
          value: this.numberOfPeople,
        },
      ];
    },
  },
  created() {
    this.setIsCustomQuoteNeeded(false);
    this.setBookingDetailsFromStore();
  },
  methods: {
    ...mapMutations({
      addErrors: 'ADD_ERRORS',
      setIsCustomQuoteNeeded: 'SearchArtist/SET_IS_CUSTOM_QUOTE_NEEDED',
    }),
    getDateAsDDMMYYYY,
    checkIsArtistToFarAway({ lat, lng }) {
      const locationData = {
        originLat: this.currentArtistLocation.lat,
        originLng: this.currentArtistLocation.lng,
        destinationLat: lat,
        destinationLng: lng,
      };
      const isCustomQuoteNeeded = Boolean(
        this.$route.query.isCustomQuote ||
        parseInt(getKilometersDistanceBetweenCoordinates(locationData)) > this.currentArtist.LocationRange
      );

      this.setIsCustomQuoteNeeded(isCustomQuoteNeeded);
    },
    handleSetPerformanceLocation(place) {
      const performanceLocation = {
        ...([typeof place.geometry.location.lat, typeof place.geometry.location.lng].every((type) => type === 'function'))
          ? { lat: place.geometry.location.lat?.(), lng: place.geometry.location.lng() }
          : { lat: place.geometry.location.lat, lng: place.geometry.location.lng }
      };
      const { address_components = [] } = place;
      const streetNumber = address_components?.find(({ types }) => types.includes('street_number'))?.long_name;
      const adress = address_components?.find(({ types }) => types.includes('route'))?.long_name;
      const postalCode = address_components?.find(({ types }) => types.includes('postal_code'))?.long_name;
      const country = address_components?.find(({ types }) => types.includes('country'))?.long_name;
      const city = address_components?.find(({ types }) => types.includes('locality') || types.includes('postal_town'))?.long_name;

      this.line1 = adress ? `${streetNumber ? `${streetNumber} ` : ''}${adress}` : '';
      this.line2 = '';
      this.city = city ?? '';
      this.postalCode = postalCode ?? '';
      this.country = country ?? '';
      this.performanceLocation = performanceLocation;
      this.checkIsArtistToFarAway(performanceLocation);
    },
    handlePerformanceDetailsSubmit() {
      const isValidForm = this.$refs.form.checkValidity();

      if (!isValidForm) return;
      if (!this.performanceLocation?.lat || !this.performanceLocation?.lng) {
        this.$refs?.['location-input']?.$el?.scrollIntoView();
        this.addErrors([{ type: 'alert', message: this.$t('artist.identity.errors.noCitySelected') }]);

        return;
      }

      const bookingDetails = {
        Line1: this.line1,
        Line2: this.line2,
        PostalCode: this.postalCode.toString(),
        City: this.city,
        Country: this.country,
        StartTime: this.startTime,
        EndTime: this.endTime,
        IsMaterialNeeded: this.isMaterialNeeded,
        NumberOfPeople: this.numberOfPeople,
        EventType: this.eventType,
        ...(this.isCustomQuote
          ? {
              name: this.name,
              customQuoteDescription: this.customQuoteDescription,
            }
          : {}
        ),
      };

      this.$emit('submit', bookingDetails);
    },
    setBookingDetailsFromStore() {
      this.eventType = this.bookingDetails.EventType || '';
      this.startTime = this.bookingDetails.StartTime || null;
      this.isMaterialNeeded = this.bookingDetails.IsMaterialNeeded || false;
      this.numberOfPeople = this.bookingDetails.NumberOfPeople || '';

      if (this.filters.localisation?.geometry?.location) this.handleSetPerformanceLocation(this.filters.localisation);
      else {
        this.line1 = this.bookingDetails.Line1 || (this.filters.localisation?.formatted_address ?? '');
        this.line2 = this.bookingDetails.Line2 || '';
        this.postalCode = this.bookingDetails.PostalCode || '';
        this.city = this.bookingDetails.City || '';
        this.country = this.bookingDetails.Country || '';
      }
    },
  },
}

</script>


<style lang="scss">

.client-booking-details-form {
  &__wrapper {
    display: grid;
    grid-gap: var(--space-md);

    @media screen and ($desktop) {
      grid-template-columns: 1fr 1fr;
      grid-column: 1 / 2;
    }
  }

  &__info-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--space-xl) var(--space-lg);
    margin-bottom: var(--space-xl);

    @media screen and ($desktop) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__quote-details {
    grid-column: 1 / -1;
  }

  &__cta {
    margin-top: var(--space-sm);
    margin-right: auto;

    @media screen and ($desktop) {
      grid-column: 1 / -1;
    }
  }
}

</style>