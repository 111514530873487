const getKilometersDistanceBetweenCoordinates = ({ originLat, originLng, destinationLat, destinationLng }) => {
  if ((originLat == destinationLat) && (originLng == destinationLng)) return 0;

  const radOriginLat = Math.PI * originLat / 180;
  const radDestinationLat = Math.PI * destinationLat / 180;
  const theta = originLng - destinationLng;
  const radtheta = Math.PI * theta / 180;
  let dist = Math.sin(radOriginLat) * Math.sin(radDestinationLat) + Math.cos(radOriginLat) * Math.cos(radDestinationLat) * Math.cos(radtheta);

  if (dist > 1) dist = 1;

  dist = Math.acos(dist);
  dist = dist * 180 / Math.PI;
  dist = dist * 60 * 1.1515;
  dist = dist * 1.609344;

  return dist;
};


export {
  getKilometersDistanceBetweenCoordinates,
};
