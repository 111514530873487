var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
  'client-booking__wrapper',
  { 'client-booking__wrapper--custom-quote': _vm.isCustomQuoteNeeded }
]},[_c('header',{staticClass:"client-booking__header"},[_c('Icon',{staticClass:"mr-lg",attrs:{"variant":'arrow-left-outline',"size":'xs',"tag":'button',"type":'button',"backdropColor":'white'},on:{"click":_vm.handleBack}}),_c('Heading',{attrs:{"tag":'h3',"level":'h3'}},[_vm._v(" "+_vm._s(_vm.isShowInfos ? _vm.$t('artist.dashboard.client.booking.details.paymentSummary') : _vm.$t('artist.dashboard.client.booking.details.eventSummary'))+" ")])],1),(_vm.isCustomQuoteNeeded)?_c('div',{staticClass:"client-booking__custom-quote-hint-wrapper"},[_c('Icon',{attrs:{"variant":'warning-solid',"color":'alert',"size":'xs'}}),_c('Paragraph',{attrs:{"color":'dark',"size":'sm'}},[_vm._v(" "+_vm._s(_vm.$t('artist.dashboard.client.booking.details.customQuoteMessage'))+" ")])],1):_vm._e(),(_vm.isLoading)?_c('Loader',{attrs:{"height":400}}):[_c('div',{staticClass:"client-booking__date"},[_c('div',[_c('Heading',{staticClass:"mb-xxs",attrs:{"tag":'h5',"level":'h5'}},[_vm._v(" "+_vm._s(_vm.$t('artist.dashboard.landing.searchHeader.eventDate'))+"* ")]),_c('Paragraph',[_vm._v(" "+_vm._s(_vm.bookingDate || _vm.$t('artist.dashboard.client.booking.details.chooseDate'))+" ")])],1),_c('router-link',{staticClass:"mb-xxs",attrs:{"to":{
          name: 'ArtistProfilePublic',
          params: { id: _vm.currentArtist.ID },
          hash: '#artist-agenda'
        }}},[_c('TextLink',{attrs:{"tag":'span'}},[_vm._v(" "+_vm._s(_vm.$t('common.edit'))+" ")])],1)],1),(!_vm.isCustomQuoteNeeded)?_c('div',{staticClass:"client-booking__sticky"},[_c('BookingDetailsCard',{staticClass:"client-booking__details-card",attrs:{"artist":_vm.currentArtist,"booking":_vm.pendingBooking}})],1):_vm._e(),_c('ClientBookingDetailsForm',{ref:"details",attrs:{"isCustomQuote":_vm.isCustomQuoteNeeded,"isShowInfos":_vm.isShowInfos},on:{"submit":_vm.handleBookingDetailsSubmit}}),(_vm.isShowInfos)?_c('ClientBookingPayment',{attrs:{"clientSecret":_vm.clientSecret},on:{"success":_vm.handlePaymentSuccess}}):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }